<template>
  <div>
    <eden-page-header
      :title="'Beautician Directory'"
      :subtitle="'Edit Beautician Profile'"
    />
    <beautician-form
      v-if="beautician.id"
      :action="'edit'"
      :beautician="beautician"
    />
  </div>
</template>

<script>
import BeauticianForm from "@/components/Logistics/Directory/Beauticians/BeauticianForm.vue";

import beauticians from "@/requests/logistics/directory/beauticians";

export default {
  name: "BeauticianEdit",
  components: { BeauticianForm },
  data() {
    return {
      beautician: {},
    };
  },
  computed: {
    beauticianId() {
      return this.$route.params.id;
    },
  },
  created() {
    this.getBeautician();
  },
  methods: {
    getBeautician() {
      beauticians
        .get(this.beauticianId)
        .then((response) => {
          if (response.data.status) {
            this.beautician = response.data.data[0];
            this.beautician.location_area_id =
              this.beautician.location_area && this.beautician.location_area.id;
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
