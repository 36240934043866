<template>
  <div v-loading="loading" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section>
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
            <el-col :md="24" class="is-flex is-flex-column is-align-center">
              <eden-image-upload
                :image-url.sync="form.avatar"
                :image-text="'profile image'"
                :image-folder="'logistics/beauticians'"
              />
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Beautician Details"
        subtitle="Tell us about this beautician"
      >
        <template slot="form-fields">
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="First name"
                prop="firstname"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.firstname"
                  placeholder="Enter first name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item
                label="Last name"
                prop="lastname"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.lastname"
                  placeholder="Enter last name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="20" class="is-flex-wrap">
            <el-col :md="12">
              <el-form-item
                label="Phone Number"
                prop="phone_number"
                :rules="validatePhone()"
              >
                <eden-phone-input :input.sync="form.phone_number" />
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="Alternate Phone Number (optional)">
                <eden-phone-input :input.sync="form.alternative_phone" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Category (You can select more than one)"
                prop="categories"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.categories"
                  multiple
                  placeholder="Select category"
                >
                  <el-option
                    v-for="type in categories"
                    :key="type.id"
                    :label="type.name"
                    :value="type.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Location area (they live in)"
                prop="location_area_id"
                :rules="validateField()"
              >
                <el-select
                  v-model="form.location_area_id"
                  filterable
                  placeholder="Select city"
                >
                  <el-option
                    v-for="location in locationareas"
                    :key="location.id"
                    :label="location.name"
                    :value="location.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="Select preferred working days"
                prop="preferred_working_days"
                :rules="validateField()"
              >
                <el-checkbox-group
                  v-model="form.preferred_working_days"
                  class="eden-custom--radio flex"
                  @change="sortServiceDays"
                >
                  <el-checkbox
                    v-for="(value, index) in serviceDays()"
                    :key="index"
                    :label="value"
                    class="small"
                    >{{ value }}</el-checkbox
                  >
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="National ID"
        subtitle="Input this beautician’s national ID details."
      >
        <template slot="form-fields">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item
                label="National ID Number"
                prop="identification_number"
                :rules="validateField()"
              >
                <el-input
                  type="text"
                  v-model="form.identification_number"
                  v-number
                  :maxlength="11"
                  placeholder="Enter their national identity number"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <div class="upload-id">
                <div class="upload-id--title">
                  <p>Upload ID Image</p>
                  <span
                    >Upload a clear image of this beautician’s national
                    ID.</span
                  >
                </div>
                <div class="upload-id--content">
                  <eden-image-upload
                    v-if="!form.identification_image"
                    :image-preview="false"
                    :image-url.sync="form.identification_image"
                    :image-folder="'logistics/beauticians'"
                    @uploading="uploading_id = true"
                    @uploaded="uploading_id = false"
                  >
                    <el-button
                      type="plain"
                      icon="eden-icon-upload"
                      size="medium"
                      :loading="uploading_id"
                      >Upload image</el-button
                    >
                  </eden-image-upload>
                  <div v-else class="image">
                    <img :src="form.identification_image" :alt="'X'" />
                    <p>{{ form.identification_number }}</p>
                    <span @click="form.identification_image = ''"
                      >Remove image</span
                    >
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="$router.go(-1)">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="add"
              >Add Beautician
            </el-button>
            <el-button v-else type="primary" :loading="updating" @click="update"
              >Save changes
            </el-button>
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import beauticians from "@/requests/logistics/directory/beauticians";
import * as actions from "@/store/action-types";

export default {
  name: "BeauticianForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    beautician: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        avatar: "",
        firstname: "",
        lastname: "",
        phone_number: "",
        alternative_phone: "",
        categories: [],
        location_area_id: "",
        preferred_working_days: [],
        identification_number: "",
        identification_image: "",
      },
      uploading_id: false,
      adding: false,
      updating: false,
    };
  },
  computed: {
    locationareas() {
      return this.$store.getters.location_areas_list;
    },
    categories() {
      const categories = this.$store.getters.service_types.beauty;
      const premium = categories.find(
        (category) => category.name.toLowerCase() === "premium"
      );
      return premium && premium.beauty_categories;
    },
  },
  created() {
    this.loading = true;
    this.$store.dispatch(actions.GET_SERVICE_TYPES, "beauty");
    this.$store.dispatch(actions.GET_LOCATION_AREAS_LIST).then(() => {
      if (this.action === "edit") {
        Object.keys(this.form).forEach((key) => {
          if(key === "categories"){
            this.form[key] = this.beautician[key].map(item=>{
              return item.id
            })
          }
          else if(key === "alternative_phone" && this.beautician[key] === null){
            this.form[key] = ""
          }
          else{
            this.form[key] = this.beautician[key];
          }
        });
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      } else {
        this.loading = false;
      }
    });
  },
  methods: {
    sortServiceDays() {
      this.form.preferred_working_days = this.sortedDaysOfWeek(
        this.form.preferred_working_days
      );
    },
    cancel() {
      this.$router.push({
        name: "directory.index",
        query: { t: "beautician" },
      });
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        beauticians
          .add(this.form)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success(message);
              this.$router.push({
                name: "logistics.directory.index",
                query: { t: "beauticians" },
              });
            }
            this.adding = false;
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        const payload = {
          beautician_id: this.beautician.id,
          ...this.form,
        };
        this.updating = true;
        beauticians
          .update(payload)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.$message.success("Beautician profile updated!");
              this.$router.push({
                name: "logistics.directory.beautician",
                params: { id: this.beautician.id },
              });
            } else {
              this.updating = false;
              this.$message.error(message);
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.eden-page-form {
  width: 80% !important;
}

.el-checkbox {
  height: 36px !important;
}

.upload-id {
  margin-bottom: 10px;

  &--title {
    p {
      font-weight: 500;
      font-size: 0.875rem;
      margin-bottom: 5px;
    }

    span {
      color: var(--eden-grey-tertiary);
      font-size: 0.875rem;
      display: inline-block;
      width: 90%;
      line-height: 1.8;
      margin-bottom: 10px;
    }
  }

  &--content {
    display: flex;
    justify-content: flex-start;

    .image {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      width: 100%;
    }

    img {
      height: 60px;
      width: auto;
      border-radius: 4px;
    }

    p {
      font-weight: 500;
      font-size: 0.875rem;
      margin-left: 5px;
      width: 70%;
    }

    span {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: var(--eden-red-primary);
      cursor: pointer;
      font-size: 0.875rem;
    }
  }
}
</style>
